import config from '@/config';
import {
  State as ProductionState,
  initialState as productionInitialState,
} from '@/store/modules/production/model';
import {
  State as FiltersState,
  initialState as filtersInitialState,
} from '@/store/modules/filters';
import {
  State as DevicesLoadStatesState,
  initialState as devicesLoadStatesInitialState,
} from '@/store/modules/devicesLoadStates/model';
import {
  State as SettingsState,
  initialState as settingsInitialState,
} from '@/store/modules/settings/model';
import {
  State as IssuesState,
  initialState as issuesInitialState,
} from '@/store/modules/issues/model';
import {
  State as EmailReportsState,
  initialState as emailReportsInitialState,
} from '@/store/modules/emailReports';

import {
  State as NotificationBarState,
  initialState as notificationBarInitialState,
} from '@/store/modules/notificationBar/model';

import {
  State as DevicesListsState,
  initialState as devicesListsInitialState,
} from '@/store/modules/devicesLists/model';

import {
  State as DeviceStatesState,
  initialState as deviceStatesInitialState,
} from '@/store/modules/deviceStates/model';

import {
  State as MaintenanceState,
  initialState as maintenanceInitialState,
} from '@/store/modules/maintenance/model';

import {
  State as AnomalyState,
  initialState as anomalyInitialState,
} from '@/store/modules/anomaly/model';

import {
  State as ScrapState,
  initialState as scrapInitialState,
} from '@/store/modules/scrap/model';

const companyDebug = localStorage.getItem('companyDebug');
const companyByDefault = companyDebug || config.defaultCompany;

const isFetchedFromCache = config.isFetchedFromCache === 'true';
const appEnvironment = config.appEnvironment || 'development';
const splittedURL = window.location.host.split('.');
// Get company from URL if there is no company set by default
export const company = !companyByDefault
  ? splittedURL[0].toLowerCase()
  : companyByDefault;

export interface CycleStates {
  states: Array<{
    state: number;
    color: string;
    description: string;
  }>;
}
export interface CycleCountSetting {
  height: number[] | null[];
  distance: number | null;
  standardCycleTime: number | null;
  smoothingEnabled: boolean | null;
  unitsPerCycle: number;
  width: number[] | null[];
  threshold: number[] | null[];
  prominence: number[] | null[];
}

// Split these in different modules
export interface Device {
  deviceid: string;
  nickname: string;
  companyid: string;
  thresholds: { [key: string]: number }[];
  speedlines: { [key: string]: number | string }[];
  ratedload?: number;
  settings: {
    anomalyDetection?: {
      enabled: boolean;
    };
    cyclesCounting: {
      cycleStatesExtended: CycleStates;
      cycleStates: CycleStates;
    } & CycleCountSetting;
    type: 'cyclesCounting' | 'speed';
    metricsTargets: {
      [key: string]: number;
    };
  };
  details: {
    usesAdditionalSensor?: boolean;
    additionalSensorMetric?: string;
  };
  normalspeed?: number;
  wastespeed?: number;
}

export type Company = {};

export type Role = {};

export type Metric = {};

export type Alert = {};

export type Recipient = {};

export type Issue = {};

export type User = {};

export type DeviceType = {};

export type LoadType = {};

export type AvailableMetrics = {
  [key: string]: {
    metric: string;
    name: string;
    unit: string;
    [key: string]: any;
  };
};

export type RootState = {
  appEnvironment: string;
  user: {
    loggedIn: Boolean;
    isLightTheme: boolean;
    loggedInUserInfo?: {
      username: string;
      level: number;
      phone?: string;
      email?: string;
      loggedInCompany?: string;
      permissions?: {
        devicesLists?: {
          listsIds?: number[];
          selectedListId: number;
        };
      };
    };
    token?: string;
  };
  selectedView: string;
  selectedDevice: number;
  selectedDeviceGroup: number;
  raw: any;
  rawDataByDevice: any;
  rawDeviceGroupData: any;
  rawArray: Array<any>;
  rawphase: any;
  rawphaseArray: Array<any>;
  energy: { days: Array<any> };
  energyAllDevices: Array<any>;
  totalEnergy: number;
  devices: Array<Device>;
  devicesData: Object;
  allDevices: Array<Device>;
  deviceGroups: Array<Device>;
  availableDeviceGroups: Array<Device>;
  companies: Array<Company>;
  roles: { roles: Array<Role> };
  metrics: Array<Metric>;
  alerts: Array<Alert>;
  notifications: Array<Notification>;
  comments: object;
  totalFilteredNotifications: number;
  recipients: Array<Recipient>;
  users: { users: Array<User> };
  company: string;
  deviceTypes: Array<DeviceType>;
  loadTypes: Array<LoadType>;
  uptimeData: Array<{ threshold: string; data: { [key: string]: number } }>;
  uptimeThresholds: Array<{ [key: number]: string }>;
  peakHigh: Array<{
    [key: number]: {
      PF: { [key: string]: { time: string; value: number } };
      kVA: {
        [key: string]: { time: string; value: number; powerFactor: number };
      };
      kW: { [key: string]: { time: string; value: number } };
    };
  }>;
  peakLow: Array<{
    [key: number]: {
      PF: { [key: string]: { time: string; value: number } };
      kVA: {
        [key: string]: { time: string; value: number; powerFactor: number };
      };
      kW: { [key: string]: { time: string; value: number } };
    };
  }>;
  avgVal: Array<{
    [key: number]: {
      PF: { [key: string]: number };
      kVA: { [key: string]: number };
      kW: { [key: string]: number };
    };
  }>;
  loadFactor: Array<{
    [key: number]: {
      kVA: { [key: string]: number };
    };
  }>;
  customSorted: boolean;
  progressEnergy: number;
  progressUptime: number;
  progressDiagnostics: number;
  progressShowPercentage: boolean;
  companyTimezone: string;
  userTimezone: string;
  browserTimezone: string;
  totalPowerDay: {};
  totalPowerWeek: {};
  alertsWidget: {};
  alertsWidgetLoading: boolean;
  alertsWidgetTop: {};
  prodPeriodStats: {
    day: [];
    week: [];
  };
  uptimeStatsPrevAvg: {
    day: boolean;
    week: boolean;
  };
  prodUnits: [];
  companySettings: Record<string, any>;
  phasesVisible: boolean;
  maxMinVisible: boolean;
  uptimeStatsWeek: {};
  uptimeStatsDay: {};
  companyData: {};
  energyWidget: {
    energy: {};
    energyAllDevices: {};
    totalEnergy: {};
  };
  energyStats: {};
  shouldRefreshHome: boolean;
  isFetchedFromCache;
  metricsTable: null;
  skus: [];
  previousBest: [];
  compareDevices: Array<string>;
  alert: {
    alertMessage: string;
    alertType: string;
    alertVisible: boolean;
    actions?: Array<{ caption: string; action: () => any }>;
  };
  locale: string;
  production: ProductionState;
  filters: FiltersState;
  devicesLoadStates: DevicesLoadStatesState;
  settings: SettingsState;
  issues: IssuesState;
  notificationBar: NotificationBarState;
  refreshWidget: {
    [key: string]: boolean;
  };
  devicesLists: DevicesListsState;
  metricStats: Array<object>;
  metricStatsData: Object;
  speedLines: Object;
  deviceStates: DeviceStatesState;
  skusDevices: Array<Object>;
  maintenance: MaintenanceState;
  deviceAvailableMetrics: Object;
  deviceDetailsOptions: {
    make: string[];
    model: string[];
    process: string[];
    material: string[];
    sensor: string[];
  };

  available_metrics: AvailableMetrics;
  anomaly: AnomalyState;
  scrap: ScrapState;
  emailReports: EmailReportsState;
};

export function getStateDefault(): RootState {
  return {
    appEnvironment,
    user: { loggedIn: false, isLightTheme: false },
    selectedView: '',
    selectedDevice: 0,
    selectedDeviceGroup: 0,
    raw: {},
    rawDataByDevice: {},
    rawDeviceGroupData: {},
    rawArray: [],
    rawphase: {},
    rawphaseArray: [],
    energy: { days: [] },
    energyAllDevices: [],
    totalEnergy: 0,
    devices: [],
    devicesData: {
      hour: {},
      day: {},
      week: {},
      month: {},
    },
    allDevices: [],
    deviceGroups: [],
    availableDeviceGroups: [],
    companies: [],
    roles: { roles: [] },
    metrics: [],
    alerts: [],
    notifications: [],
    comments: {
      issue_details: {},
      production_entries: {},
    },
    totalFilteredNotifications: 0,
    recipients: [],
    users: { users: [] },
    company,
    deviceTypes: [],
    loadTypes: [],
    uptimeData: [],
    uptimeThresholds: [],
    peakHigh: [],
    peakLow: [],
    avgVal: [],
    loadFactor: [],
    customSorted: false,
    progressEnergy: 0,
    progressUptime: 0,
    progressDiagnostics: 0,
    progressShowPercentage: false,
    companyTimezone: 'Africa/Nairobi',
    userTimezone: '',
    browserTimezone: '',
    totalPowerDay: {},
    totalPowerWeek: {},
    alertsWidget: {},
    alertsWidgetLoading: true,
    alertsWidgetTop: {},
    prodPeriodStats: {
      day: [],
      week: [],
    },
    uptimeStatsPrevAvg: {
      day: false,
      week: false,
    },
    prodUnits: [],
    companySettings: {},
    phasesVisible: false,
    maxMinVisible: false,
    uptimeStatsWeek: {},
    uptimeStatsDay: {},
    companyData: {},
    energyWidget: {
      energy: {},
      energyAllDevices: {},
      totalEnergy: {},
    },
    energyStats: {},
    shouldRefreshHome: false,
    isFetchedFromCache,
    metricsTable: null,
    skus: [],
    previousBest: [],
    compareDevices: [],
    alert: {
      alertMessage: '',
      alertType: 'success',
      alertVisible: false,
      actions: [],
    },
    locale: 'en',
    production: productionInitialState,
    filters: filtersInitialState,
    devicesLoadStates: devicesLoadStatesInitialState,
    settings: settingsInitialState,
    issues: issuesInitialState,
    notificationBar: notificationBarInitialState,
    refreshWidget: {},
    devicesLists: devicesListsInitialState,
    // In the future, these could be part of settings.
    metricStats: [
      {
        metric: 'Psum',
        name: 'Active Power',
        unit: 'kW',
      },
      {
        metric: 'Ssum',
        name: 'Apparent Power',
        unit: 'kVA',
      },
      {
        metric: 'PFavg',
        name: 'Power Factor',
        unit: 'PF',
      },
    ],
    metricStatsData: {},
    speedLines: {},
    deviceStates: deviceStatesInitialState,
    skusDevices: [],
    maintenance: maintenanceInitialState,
    deviceAvailableMetrics: {},
    deviceDetailsOptions: {
      make: [],
      model: [],
      process: [],
      material: [],
      sensor: [],
    },
    available_metrics: {},
    anomaly: anomalyInitialState,
    scrap: scrapInitialState,
    emailReports: emailReportsInitialState,
  };
}

const state: RootState = getStateDefault();

// state.devices = localStorage.globaldevices || [];
// state.deviceTypes = localStorage.globaldevicetypes || [];

export default state;

// ==== Documenting variables used in localStorage ==== //
// globaldevices = devices saved by the customer
// globaldevicetypes = devicetypes available to customer fetched from DB
// homedata = data on graph home page
// homemetrics = data on home page for graph metrics
// diagdevicetype = last selected device type in diagnostics page
// diagdeviceid = last selected device id in diagnostics page
// diagmetrics = data on device metrics in diagnostics page
// diagdata = chart data for last selected device in diagnostics page
// diagrawdata = raw chart data for selected device in diagnostics page
// diagmetricasked = specific metric requested while on the diagnostics page
// diagtimediff = time difference of date range selected while on the diagnostics page
// diagtimerange = date range selected while on the diagnostics page
