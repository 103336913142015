import store from '@/store';

type Frequency = 'hour' | 'minute' | 'second';

export type Rounding = 'integer' | 'one' | 'two' | 'no';

export type CumulativeMetricOptions = {
  config: {
    cumulative: true;
    frequency: Frequency;
    rounding: Rounding;
  };
};

type Data = {
  recValue: number | null;
};

type NonNullData = {
  recValue: number;
};

const getAccValueBasedOnFrequency = (
  minuteLevelAccValue: number,
  frequency: Frequency
) => {
  if (frequency === 'hour') {
    return minuteLevelAccValue / 60;
  } else if (frequency === 'second') {
    return minuteLevelAccValue * 60;
  }

  return minuteLevelAccValue;
};

export const getNumeratorFromUnit = (unit: string) => {
  return unit?.split('/')?.[0] || unit;
};

export const getRoundingFixedDecimalsForMetric = (
  metric: string | undefined
) => {
  const options = store.getters.getAvailableMetrics[
    metric?.toLowerCase?.() || ''
  ]?.options as Partial<CumulativeMetricOptions>;
  const rounding = options?.config?.rounding || 'no';

  return getRoundingFixedDecimals(rounding);
};

export const getRoundingFixedDecimals = (rounding: Rounding) => {
  if (rounding === 'one') {
    return 1;
  } else if (rounding === 'integer') {
    return 0;
  }
  return 2;
};

const getRoundedValue = (value: number, rounding: Rounding) => {
  if (rounding === 'integer') {
    return Math.round(value);
  } else if (rounding === 'one') {
    return Math.round(value * 10) / 10;
  } else if (rounding === 'two') {
    return Math.round(value * 100) / 100;
  }

  return value;
};

type GetCumulativeMetricValuesProps = {
  metric: string;
  data: Data[];
  from: number;
  to: number;
};

type GetCumulativeMetricValuesResult = {
  average: number;
  accumulated: number;
};

/**
 * Get the `average` and `accumulated` values based on
 * the metric's rated frequency and rounding configuration, given:
 * - minute-level `data`,
 * - a time range (`from` and `to`) in milliseconds,
 * - and a `metric`.
 *
 * If the metric is rated as 'per hour', the minute-level accumulated value
 * will be divided by 60.
 *
 * If the metric is rated as 'per second', the minute-level accumulated value
 * will be multiplied by 60.
 *
 * Finally, the accumulated value will be rounded based on the metric's rounding configuration.
 *
 */
export const getCumulativeMetricValues = ({
  metric,
  data,
  from,
  to,
}: GetCumulativeMetricValuesProps) => {
  const options = store.getters.getAvailableMetrics[metric.toLowerCase()]
    ?.options as Partial<CumulativeMetricOptions>;

  const frequency = options?.config?.frequency || 'minute';
  const rounding = options?.config?.rounding || 'no';

  const nonNullDataPoints = data.filter(
    (value): value is NonNullData => value.recValue !== null
  );

  if (!nonNullDataPoints.length) {
    return null;
  }

  const valuesSum = nonNullDataPoints.reduce(
    (acc, { recValue }) => acc + recValue,
    0
  );

  const average = valuesSum / nonNullDataPoints.length;

  const rateLevelAccValue = getAccValueBasedOnFrequency(valuesSum, frequency);
  const roundedAccValue = getRoundedValue(rateLevelAccValue, rounding);

  const result: GetCumulativeMetricValuesResult = {
    average,
    accumulated: roundedAccValue,
  };

  return result;
};
